import React, { Suspense, lazy, Component } from "react";
import "core-js/modules/es.object.from-entries";

import { Route, Switch } from "react-router"; // react-router v4
import * as Sentry from "@sentry/browser";

import "./Font.css";
import "./App.css";
import Mitfullsize from "./Mitfullsize";
if (!process.env.REACT_APP_OFFLINE) {
  Sentry.init({
    dsn: "https://afcc00b88e5f40eab08401489e6173b8@sentry.io/1367855"
  });
}

const LoadingMessage = () => <i className="fa fa-spinner fa-spin" />;
function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}
const Test = lazyWithPreload(() => import("layouts/Test"));

const Admin = lazyWithPreload(() => import("layouts/Admin"));

const User = lazyWithPreload(() => import("layouts/User"));

const Public = lazyWithPreload(() => import("layouts/Public"));

class App extends Component {
  render() {
    return (
      <div className="App">
        <Suspense fallback={<LoadingMessage />}>
          <Switch>
            <Route path="/experimentos/" component={Test} />
            <Route path="/admin/" component={Admin} />
            <Route path="/user/" component={User} />
            <Route path="/" component={Public} />
          </Switch>
        </Suspense>
      </div>
    );
  }
}

export default App;
