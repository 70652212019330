import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "./img/logo_conjunto.svg";
import pdf from './docs/Navarrete Zegras_ Final Resutls_Feb2020_UPLOAD.pptx.pdf'

import "./Mitfullsize.css";
class Mitfullsize extends Component {
  constructor(props) {
    super(props);

    this.setLang = this.setLang.bind(this);
  }
  setLang(e) {
    const { lang, url } = e.target.dataset;
    localStorage.setItem("language", lang);
    localStorage.setItem("redirect_url", window.location.origin + url);
    window.location.href = window.location.origin + url;
  }
  render() {
    return (
      <React.Fragment>
        <div className="Mitfullsize">
          <div className="bg"></div>
          <div class="container">
            <div className="heading">
              <img
                className="urban img-fluid"
                src={logo}
                alt="MIT and Urban Experiment"
                height="80"/>
            </div>    
            <div className="containerx">
              <div className="avisoPaper">
                <p><strong>Mind the Perception Gap</strong>: The Impact of Bus Rapid Transit Design on Citizens’ Perceptions of Subjective Wellbeing.</p>
                <a href={pdf}><button type="button" class="btn btn-outline-warning">Download Link</button></a>
              </div>
              <p>
                By <strong>Pablo Navarrete-Hernandez</strong> (University of Chile &amp; Politecnico di Milano) &amp; <strong>Christopher Zegras</strong> (Massachusetts Institute of Technology)
              </p>
              <p className="paperDescription">
                Over the past 20 years, bus rapid transit (BRT) has been increasingly promoted as an affordable way to improve public transit service in cities around the world. In many places, however, BRT projects have faced community opposition for a range of reasons, such as concerns about loss of streetspace and parking and impacts on urban aesthetics. This research intends to help inform the design of BRT infrastructure from the perspective of people’s subjective wellbeing (SWB), as measured by reported happiness and safety levels. Specifically, we use a novel experimental method, in a randomized control trial framework, that involves individuals reporting their happiness or safety levels when presented with photo simulations of various possible BRT designs. We test the approach on approximately 2 km of Summer Street, in Boston’s Seaport District, exploring the impact of 65 different types of street design options. Over nearly three months, we collected data from over 1,400 individuals who rated a total of almost 35,000 images. We have three main findings: (1) compared to regular bus service, a “standard BRT” consisting of a painted bus and bike lane significantly enhances SWB; (2) an “improved BRT,” incorporating infrastructure improvements for cyclists and pedestrians and adding street amenities further enhance SWB; and, (3) specific BRT design elements most effective in enhancing SWB are adding green spaces, improving crosswalks, and providing more space for pedestrians and cyclists. Overall, the results suggest the importance, in terms of increasing community SWB, of moving from a traditional bus-only approach to multi-modal type BRT infrastructures. Future research could focus on estimating the investment costs associated with the changes in SWB and also applying the technique in participatory planning processes to select BRT designs on corridors of specific interest, in Boston and beyond. 
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    type: state.auth.type,
    token: state.auth.token
  };
}
export default connect(mapStateToProps)(Mitfullsize);
